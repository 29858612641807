function Footer({ onComponentChange }) {
    const openGoogleMaps = () => {
        // Replace 'LATITUDE' and 'LONGITUDE' with the actual coordinates or replace 'ADDRESS' with the address
        const googleMapsUrl = `https://maps.app.goo.gl/zkP95KVQ6Wy1W2RK6`;

        window.open(googleMapsUrl, '_blank');
    };

    const openNewWindow = (url) => {
        window.open(url, '_blank');
    };
    return (
        <footer className="footer_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-4 footer_col">
                        <div className="footer_contact">
                            <h4 style={{color: "ivory"}}>Reach at..</h4>
                            <div className="contact_link_box">
                                <a href="" onClick={() => openGoogleMaps()}>
                                    <i style={{color: "ivory"}} className="fa fa-map-marker" aria-hidden="true"></i>
                                    <span style={{color: "ivory"}}>Location</span>
                                </a>
                                <a href="tel: +971 03 755 0044">
                                    <i style={{color: "ivory"}} className="fa fa-phone" aria-hidden="true"></i>
                                    <span style={{color: "ivory"}}>Call 03 755 0044</span>
                                </a>
                                {/* <a href="">
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <span>demo@gmail.com</span>
                                </a> */}
                            </div>
                        </div>
                        <div className="footer_social">
                            {/* <a href="#" >
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                            <a href="">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                            <a href="">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </a> */}
                            <a href="#" onClick={() => openNewWindow("https://www.instagram.com/ivory.mc.ae/?hl=en")}>
                                <i style={{color: "ivory"}} className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-lg-3 footer_col">
                        <div className="footer_detail">
                            <h4>About</h4>
                            <p>
                                Beatae provident nobis mollitia magnam voluptatum, unde dicta facilis minima veniam corporis laudantium
                                alias tenetur eveniet illum reprehenderit fugit a delectus officiis blanditiis ea.
                            </p>
                        </div>
                    </div> */}
                    <div className="col-md-6 col-lg-4 mx-auto footer_col">
                        <div className="footer_link_box">
                            <h4 style={{color: "ivory"}}>Links</h4>
                            <div className="footer_links">
                                <a className="" style={{color: "ivory"}} href="#" onClick={() => onComponentChange("home")}>
                                    Home
                                </a>
                                <a className="active" style={{color: "ivory"}} href="#" onClick={() => onComponentChange("about")}>
                                    About
                                </a>
                                <a className="" style={{color: "ivory"}} href="#" onClick={() => onComponentChange("specialities")}>
                                    Specialities
                                </a>
                                <a className="" style={{color: "ivory"}} href="#" onClick={() => onComponentChange("doctors")}>
                                    Doctors
                                </a>
                                <a className="" style={{color: "ivory"}} href="#" onClick={() => onComponentChange("contact")}>
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 footer_col">
                        <div className="footer-info">
                            <p>
                                &copy;  All Rights Reserved By Ivory Medical Center 2024
                                <a href="https://html.design/"><br /><br /></a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )

}
export default Footer
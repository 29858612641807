import React, { useState } from 'react';
import Appointments from '../Appointments/Appointments';



function Slider({ onComponentChange }) {

    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    return (
        <div className="hero_area">

            <header className="header_section">
                <div className="container">
                    <nav className="navbar navbar-expand-lg custom_nav-container">
                        <a className="navbar-brand" href="#">
                            <img src='logo.png' style={{ maxWidth: "150px" }} onClick={() => onComponentChange('home')}/>
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className=""> </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <a className="nav-link" href="#" style={{color: "ivory"}} onClick={() => onComponentChange('home')}>
                                        Home 
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" style={{color: "ivory"}} href="#" onClick={() => onComponentChange('about')}>
                                        About
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" style={{color: "ivory"}} href="#" onClick={() => onComponentChange('specialities')}>
                                        Specialities
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" style={{color: "ivory"}} href="#" onClick={() => onComponentChange('doctors')}>
                                        Doctors
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" style={{color: "ivory"}} href="#" onClick={() => onComponentChange('contact')}>
                                        Contact Us
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" style={{backgroundColor: "black", color: "ivory"}} href="#" onClick={() => handleShowAppointment()}>
                                        Make an appointment
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <div className="hero_bg_box" style={{ backgroundColor: "ivory" }}>
                <img src="images/hero-bg-new.png" alt="" />
            </div>
            {/* Slider Section */}
            <section className="slider_section">
                <Appointments show={showAppointment} handleClose={handleCloseAppointment} />
                <div id="customCarousel1" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="detail-box">
                                            <h1 style={{ color: "ivory", fontSize: "30px"}}>Welcome to Ivory Medical Center – Al Ain's Landmark for Dental and Beauty</h1>
                                            <p style={{ color: "ivory"}}>
                                                Welcome to Ivory Medical Center, the renowned medical destination nestled in the bustling heart of Al Ain. Our commitment to excellence in healthcare is evident in every aspect of our practice, from the serene environment to the cutting-edge treatments. Whether you seek a radiant smile, flawless skin, or the benefits of advanced laser technology, Ivory Medical Center is your trusted partner in achieving your health and beauty goals.
                                            </p>
                                            {/* <div className="btn-box">
                                                <a href="" className="btn1">
                                                    Read More
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="detail-box">
                                            <h1 style={{ color: "ivory", fontSize: "30px"}}>Diverse Specialties Catering to Your Oral Health & Aesthetics</h1>
                                            <p style={{ color: "ivory"}}>
                                                At Ivory Medical Center, we take pride in offering a wide range of diverse specialties that are dedicated to catering to both your oral health and aesthetics. Our team of experienced and skilled dental professionals is committed to providing comprehensive and personalized care to meet your unique needs. Whether you require routine check-ups, restorative treatments, orthodontic solutions, or cosmetic enhancements, we have the expertise and advanced technology to deliver exceptional results. We understand that oral health is intricately linked to your overall well-being, and we strive to create a warm and welcoming environment where you can feel comfortable and confident in receiving the highest quality care. With our diverse specialties, we are here to help you achieve optimal oral health and enhance your smile, ensuring that you leave our clinic with a renewed sense of confidence and satisfaction.
                                            </p>
                                            {/* <div className="btn-box">
                                                <a href="" className="btn1">
                                                    Read More
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="detail-box">
                                            <h1 style={{ color: "ivory", fontSize: "30px"}}>Ivory Medical Center: Your Gateway to Health and Confidence</h1>
                                            <p style={{ color: "ivory"}}>
                                                We are a multidisciplinary clinic offering a comprehensive range of services in Dermatology, Oral and Maxillofacial Surgery, Orthodontics, Endodontics, Pediatric Dentistry, Periodontics, and Prosthodontics. Our team of highly skilled and compassionate specialists is dedicated to providing personalized care tailored to meet your unique needs. From advanced skin treatments to transformative dental procedures, we strive to enhance your health and boost your confidence, ensuring that you leave our center feeling empowered and ready to take on the world. At Ivory Medical Center, we believe that a healthy body and a radiant smile are the keys to a fulfilling life, and we are here to guide you on your journey towards optimal well-being.
                                            </p>
                                            {/* <div className="btn-box">
                                                <a href="" className="btn1">
                                                    Read More
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ol className="carousel-indicators">
                        <li data-target="#customCarousel1" data-slide-to="0" className="active"></li>
                        <li data-target="#customCarousel1" data-slide-to="1"></li>
                        <li data-target="#customCarousel1" data-slide-to="2"></li>
                    </ol>
                </div>
            </section>
            {/* End Slider Section */}
        </div>
    )

}
export default Slider
import * as FaIcons from 'react-icons/fa';



function SpecialititiesList() {

    const featuresData = [


        {
            imgSrc: 'images/services/Endocrinology.jpg',
            alt: 'Dentistry',
            icon: 'FaRegSun',
            title: 'Dermatology',
            desc: "AACSH integrates medical knowledge and extensive care to treat diabetes and other endocrine-related disorders, having one of the best diabetes specialists in Dubai At AACSH our Endocrinologists and Diabetes specialists use advanced technology and innovative equipment, which add to the long-term commitment to patient care. The team renders comprehensive care and non-invasive diagnostic services to all the patients. The Department offers expert consultation, counseling, diagnosing the various problems early, and treating them with great skill and experience."
        },

        {
            imgSrc: 'images/services/dermatology.jpg',
            alt: 'Dentistry',
            icon: 'FaRegSun',
            title: 'Oral and Maxillofacial Surgery',
            desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
        },

        {
            imgSrc: 'images/services/CosmeticSurgery.jpg',
            alt: 'Dentistry',
            icon: 'FaTeeth',
            title: 'Orthodontics',
            desc: "At the American Academy of Cosmetic Surgery Hospital we offer a range of cutting-edge cosmetic surgery procedures designed to enhance your natural beauty and boost your self-confidence. Our team of highly skilled and experienced surgeons is dedicated to providing you with personalized care and achieving your aesthetic goals."
        },
        {
            imgSrc: 'images/services/Bariatric.jpg',
            alt: 'Dentistry',
            icon: 'FaTeethOpen',
            title: 'Endodontics',
            desc: "Obesity is a complex medical condition characterized by excessive body weight due to the accumulation of excess fat. It can lead to various health complications such as diabetes, cardiovascular disease, joint issues, and more. Bariatric services aim to help individuals manage and overcome obesity through a combination of lifestyle changes, dietary modifications, and, in some cases, surgical procedures.AACSH provides ethical, evidence-based obesity management programs according to recognized international standards of patient care.The multi-disciplinary approach program offers the following services:",
            
        },
        {
            imgSrc: 'images/services/gynecology.jpg',
            alt: 'Dentistry',
            icon: 'FaChild',
            title: 'Pediatric Dentistry',
            desc: "Every woman deserves to feel confident and comfortable in her own body.We offer a comprehensive range of surgical and non-surgical treatments specifically designed to address the unique needs and concerns of women seeking cosmetic gynecology procedures. Our team of highly skilled and experienced cosmetic gynecologists are dedicated to providing the highest standard of care in a compassionate and supportive environment."
        },

        {
            imgSrc: 'services/Periodontics.jpg',
            alt: 'Dietetics',
            icon: 'FaTeeth',
            title: 'Periodontics',
            desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
        },
        {
            imgSrc: 'images/services/Dietetics.png',
            alt: 'Dietetics',
            icon: 'FaTeeth',
            title: 'Prosthodontics',
            desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
        },
        {
            imgSrc: 'images/services/Dietetics.png',
            alt: 'Dietetics',
            icon: 'FaTooth',
            title: 'Dentistry',
            desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
        },

    ];

    
    return (
        <section className="department_section layout_padding" style={{ backgroundColor: "ivory" }}>
            <div className="department_container">
                <div className="container">
                    <div className="heading_container heading_center">
                        <h2 style={{color: "black", fontSize: "25px"}}>Our Specialities</h2>
                        {/* <p>
                            Asperiores sunt consectetur impedit nulla molestiae delectus repellat laborum dolores doloremque accusantium
                        </p> */}
                    </div>
                    <div className="row">
                        {featuresData.map((speciality, index) => {
                            // Dynamically get the corresponding icon component
                            const IconComponent = FaIcons[speciality.icon];

                            return (
                                <div className="col-md-3" key={index}>
                                    <div className="box" style={{backgroundColor: "ivory"}}>
                                        <div className="img-box">
                                            {/* Render the dynamically imported icon component */}
                                            <IconComponent style={{ color: "ivory" }} size={25} />
                                        </div>
                                        <div className="detail-box">
                                            <h5 style={{fontSize: "15px"}}>{speciality.title}</h5>
                                            {/* <p>
                                                fact that a reader will be distracted by the readable page when looking at its layout.
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>

    )

}
export default SpecialititiesList
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
    appearance: none;
    border: 0;
    outline: 0;
    background: none;
    color: inherit;
    box-shadow: none;
  }
  select::-ms-expand {
    display: none;
  }
  /* Custom Select wrapper */
  .select {
    position: relative;
    display: flex;
    width: min(20rem, 90vw);
    background: var(--background-select);
    border-radius: 0.25rem;
    overflow: hidden;
   
    select {
      flex: 1 1;
      padding: 1em;
      cursor: pointer;
    }

    &::after {
      content: "\\25BC";
      position: absolute;
      right: 1rem;
      top: 1rem;
      transition: 0.25s all ease;
      pointer-events: none; 
    }

    &:hover::after {
      color: #f39c12;
      animation: bounce 0.5s infinite;
    }
  }
  @keyframes bounce {
    25% {
      transform: translatey(5px);
    }
    75% {
      transform: translatey(-5px);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Doctors/Doctors.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;EACA;IACE,aAAa;EACf;EACA,0BAA0B;EAC1B;IACE,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,oCAAoC;IACpC,sBAAsB;IACtB,gBAAgB;;IAEhB;MACE,SAAO;MACP,YAAY;MACZ,eAAe;IACjB;;IAEA;MACE,gBAAgB;MAChB,kBAAkB;MAClB,WAAW;MACX,SAAS;MACT,0BAA0B;MAC1B,oBAAoB;IACtB;;IAEA;MACE,cAAc;MACd,+BAA+B;IACjC;EACF;EACA;IACE;MACE,0BAA0B;IAC5B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":["select {\r\n    appearance: none;\r\n    border: 0;\r\n    outline: 0;\r\n    background: none;\r\n    color: inherit;\r\n    box-shadow: none;\r\n  }\r\n  select::-ms-expand {\r\n    display: none;\r\n  }\r\n  /* Custom Select wrapper */\r\n  .select {\r\n    position: relative;\r\n    display: flex;\r\n    width: min(20rem, 90vw);\r\n    background: var(--background-select);\r\n    border-radius: 0.25rem;\r\n    overflow: hidden;\r\n   \r\n    select {\r\n      flex: 1;\r\n      padding: 1em;\r\n      cursor: pointer;\r\n    }\r\n\r\n    &::after {\r\n      content: \"\\25BC\";\r\n      position: absolute;\r\n      right: 1rem;\r\n      top: 1rem;\r\n      transition: 0.25s all ease;\r\n      pointer-events: none; \r\n    }\r\n\r\n    &:hover::after {\r\n      color: #f39c12;\r\n      animation: bounce 0.5s infinite;\r\n    }\r\n  }\r\n  @keyframes bounce {\r\n    25% {\r\n      transform: translatey(5px);\r\n    }\r\n    75% {\r\n      transform: translatey(-5px);\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

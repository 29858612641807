import React, { useState, useEffect } from 'react';
import Appointments from '../Appointments/Appointments';


function Contact({ onComponentChange }) {
    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    return (
        <div>
            <Appointments show={showAppointment} handleClose={handleCloseAppointment} />

            <div className="sub_page">
                <div className="hero_area">
                    {/* Header Section */}
                    <header className="header_section">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg custom_nav-container">
                                <a className="navbar-brand" href="#">
                                <img src='logo.png' style={{maxWidth: "100px"}} onClick={() => onComponentChange('home')}/>
                                </a>

                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className=""> </span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('home')}>
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item active">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('about')}>
                                                About
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('specialities')}>
                                                Specialities
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('doctors')}>
                                                Doctors
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('contact')}>
                                                Contact Us
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => handleShowAppointment()}>
                                                Make an Appointment
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>
                </div>
            </div>
            <section className="contact_section layout_padding" style={{ backgroundColor: "ivory"}}>
                <div className="container">
                    <div className="heading_container">
                        <h2 style={{color: "black", fontSize: "25px"}}>
                            Get In Touch
                        </h2>
                        <p>Reach out to Ivory Medical Center for excellence in dental, dermatology, facial treatments, and laser hair removal. Connect with us through our website, phone, or by visiting us at our prime location in Al Ain.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form_container contact-form">
                                <form action="">
                                    <div className="form-row">
                                        <div className="col-lg-6">
                                            <div>
                                                <input type="text" placeholder="Your Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <input type="text" placeholder="Phone Number" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <input type="email" placeholder="Email" />
                                    </div>
                                    <div>
                                        <input type="text" className="message-box" placeholder="Message" />
                                    </div>
                                    <div className="btn_box" >
                                        <button style={{ backgroundColor: "black", borderColor: "black" }}>
                                            SEND
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="map_container">
                                <div className="map">
                                    <iframe style={{ border: '0', width: '100%', height: '350px' }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14548.395544445786!2d55.7211101!3d24.2732764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8ab6caa99e813f%3A0x527f3a3b62a4f84e!2zSXZvcnkgTWVkaWNhbCBDZW50ZXIgLSDZhdix2YPYsiDYo9mK2YHZiNix2Yog2KfZhNi32KjZig!5e0!3m2!1sen!2sae!4v1704712032421!5m2!1sen!2sae" frameBorder="0" allowFullScreen title="Google Map"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Contact
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { FaX } from "react-icons/fa6";
import emailjs from 'emailjs-com';

function Appointments({ show, handleClose, doctor }) {
    console.log(doctor)
    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const allDoctors = [
        { id: 1, name: 'Dr. Ahmed Adil', image: '/Doctors/adil.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Altayeb Saeed', image: '/Doctors/saeed.jpg', speciality: 'Specialist Oral and Maxillofacial Surgery', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Anas Almidani', image: '/Doctors/almidani.jpg', speciality: 'Specialist Oral and Maxillofacial Surgery', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Khaled Alkateb', image: '/Doctors/khaled.jpg', speciality: 'Specialist Orthodontics', languages: ['English', 'Arabic'] },
        { id: 6, name: 'Dr. Reem Hassan', image: '/Doctors/reem1.jpg', speciality: 'Specialist Endodontics', languages: ['English', 'Arabic'] },
        { id: 7, name: 'Dr. Nada Almaqadm', image: '/Doctors/nada.jpg', speciality: 'General Dentist', languages: ['English', 'Arabic'] },
        { id: 8, name: 'Dr. Essam Abdalla', image: '/Doctors/essam.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 9, name: 'Dr. Rawan Hussein', image: '/Doctors/rawan1.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 10, name: 'Dr. Akram Adil', image: '/Doctors/akram.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 11, name: 'Dr. Esra Mahmud', image: '/Doctors/esra.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 12, name: 'Dr. Mohammed Rehan', image: '/Doctors/rehan.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
    ];

    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));

    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language)));
    });

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fwgrqew', 'template_4v62mp8', e.target, 'tKbfD7Qc7HH_s5Z5R')
            .then((result) => {
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });

    }

    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header style={{ backgroundColor: "ivory"}}>
                <div className="btn_box" >
                    <button style={{ backgroundColor: "black", borderColor: "black", color: "white" }} onClick={() => handleClose()}>
                        <FaX />
                    </button>
                </div>
            </Modal.Header >
            <Modal.Body style={{ backgroundColor: "ivory"}}>
                <section className="contact_section layout_padding" style={{ backgroundColor: "ivory"}}>
                    <div className="container">
                        <div className="heading_container" style={{ alignContent: "center" }}>
                            <h2 style={{color: "black", fontSize: "25px"}}>
                                Make an Appointment
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form_container contact-form">
                                    <form onSubmit={sendEmail}>
                                        <div className="form-row">
                                            <div className="col-lg-4">
                                                <div>
                                                    <input type="text" placeholder="Your Name" name="name"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div>
                                                    <input type="text" placeholder="Phone Number" name="phone"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div>
                                                    <input type="email" placeholder="Email" name="email"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-lg-4">
                                                <div>
                                                    <input type="date" placeholder="Email" name="date"/>
                                                </div>
                                                <div>
                                                    <input type="hidden" placeholder="Email" name="sendTarget" value="info@ivorymc.ae"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                               
                                                {doctor ? (
                                                    // If doctor prop is defined, show a single option with the doctor's speciality
                                                    <option value={doctor.speciality}>
                                                        {doctor.speciality}
                                                    </option>
                                                ) : (
                                                    <Form.Select name="service" onChange={handleSpecialityChange} className="" aria-label="Doctor Speciality" style={{ width: "100%" }}>
                                                        {specialities.map((speciality, index) => (
                                                            <option key={index} value={speciality}>
                                                               Speciality: {speciality}
                                                            </option>
                                                        ))}

                                                    </Form.Select>
                                                )}
                                            </div>
                                            <div className="col-lg-4">
                                                {doctor ? (
                                                    // If doctor prop is defined, show a single option with the doctor's name
                                                    <option value={doctor.name}>
                                                        {doctor.name}
                                                    </option>
                                                ) : (
                                                    <Form.Select name="doctor" onChange={handleSpecialityChange} className="" aria-label="Doctor Speciality" style={{ width: "100%" }}>
                                                        {filteredDoctors.map((doc, index) => (
                                                            <option key={index} value={doc.name}>
                                                                Doctor: {doc.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-lg-12">
                                                <div>
                                                    <input type="text" className="message-box" placeholder="Message" name="message"/>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="btn_box" >
                                            <button style={{ backgroundColor: "black", borderColor: "black" }} type="submit">
                                                SEND
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}
export default Appointments
import logo from './logo.svg';
import './App.css';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Slider from './components/Slider/Slider';
import AboutUs from './components/AboutUs/AboutUs';
import Footer from './components/Footer/Footer';
import React, { useState } from 'react';
import Specialities from './components/Specialities/Specialities';
import Doctors from './components/Doctors/Doctors';
import Contact from './components/Contact/contact';
import FloatingContact from './components/FloatingContact/FloatingContact';
function App() {

  const [activeComponent, setActiveComponent] = useState('home');

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };
  return (
    <div>
       {activeComponent === 'home' && <Home onComponentChange={handleComponentChange} />}
       {activeComponent === 'about' && <AboutUs onComponentChange={handleComponentChange} />}
       {activeComponent === 'specialities' && <Specialities onComponentChange={handleComponentChange} />}
       {activeComponent === 'doctors' && <Doctors onComponentChange={handleComponentChange} />}
       {activeComponent === 'contact' && <Contact onComponentChange={handleComponentChange} />}
       <FloatingContact />
      <Footer onComponentChange={handleComponentChange}/>
    </div>

  );
}

export default App;

import SpecialititiesList from "../SpecialitiesList/SpecialitiesList";
import Slider from "../Slider/Slider";
function Home({ onComponentChange }) {
    return (
        <div>
            <Slider onComponentChange={onComponentChange}/>
            <SpecialititiesList />
             <section className="about_section layout_padding" style={{backgroundColor: "ivory"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="img-box">
                                <img src="1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="img-box">
                                <img src="2.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="img-box">
                                <img src="3.jpg" alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </div>

    );

}
export default Home
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { FaFacebookF } from "react-icons/fa6";
import Appointments from '../Appointments/Appointments';
import React, { useState, useEffect } from 'react';

function Specialities({ onComponentChange }) {

    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);

    const featuresData = [


        {
            imgSrc: 'services/dermatology.jpg',
            alt: 'Dermatology',
            icon: 'FaRegSun',
            title: 'Dermatology',
            desc: "At Ivory Medical Center, our Dermatology and Antiaging services blend cutting-edge science with personalized care to revitalize your skin and combat the signs of aging. Our dermatologists are experts in treating a wide range of skin conditions, from acne to eczema, and are also skilled in the latest antiaging techniques, including botox, fillers, and laser therapies. We focus on enhancing your natural beauty and promoting skin health, giving you a youthful, vibrant complexion that reflects your inner vitality."
        },

        {
            imgSrc: 'services/oral.jpg',
            alt: 'Oral and Maxillofacial Surgery',
            icon: 'FaRegSun',
            title: 'Oral and Maxillofacial Surgery',
            desc: "Our Oral and Maxillofacial Surgery services offer comprehensive solutions for a variety of conditions affecting the mouth, teeth, jaws, and facial structures. Whether you require wisdom teeth removal, dental implants, or corrective jaw surgery, our skilled maxillofacial surgeons are equipped with the latest technology to ensure precise and effective treatment. We are committed to providing exceptional care and support throughout your surgical journey to improve your oral function, appearance, and overall quality of life."
        },

        {
            imgSrc: 'services/ortho.jpg',
            alt: 'Orthodontics',
            icon: 'FaTeeth',
            title: 'Orthodontics',
            desc: "The Orthodontics services at Ivory Medical Center are dedicated to creating beautiful, straight smiles for patients of all ages. Using a personalized approach, our orthodontists provide a range of treatments, including traditional braces, clear aligners, and other orthodontic appliances. We are committed to using the latest techniques and technologies to achieve optimal results, ensuring that your journey to a perfect smile is as comfortable and efficient as possible."
        },
        {
            imgSrc: 'services/Endocrinology.jpg',
            alt: 'Endodontics',
            icon: 'FaTeethOpen',
            title: 'Endodontics',
            desc: "Endodontics is the dental specialty concerned with the study and treatment of the dental pulp. At Ivory Medical Center, our endodontists are experts in saving teeth, specializing in procedures like root canal therapy, which is designed to treat problems within the tooth's soft core (dental pulp). Advanced techniques and technologies, such as operating microscopes and digital imaging, enable us to perform these procedures with the highest level of precision and success.",

        },
        {
            imgSrc: 'services/pediatric.jpg',
            alt: 'Pediatric Dentistry',
            icon: 'FaChild',
            title: 'Pediatric Dentistry',
            desc: "In our Pediatric Dentistry department, we understand the unique dental needs of children. Our pediatric dentists provide comprehensive oral health care that includes the diagnosis and treatment of dental diseases, preventive care, and educational resources to help children maintain healthy smiles. We create a child-friendly environment that makes dental visits a positive experience for both children and parents, fostering healthy habits that will last a lifetime."
        },

        {
            imgSrc: 'services/Periodontics.jpg',
            alt: 'Periodontics',
            icon: 'FaTeeth',
            title: 'Periodontics',
            desc: "Periodontics is the dental specialty focusing on the inflammatory disease that destroys the gums and other supporting structures around the teeth. At Ivory Medical Center, our periodontists are experts in the prevention, diagnosis, and treatment of periodontal disease, and in the placement of dental implants. We offer a variety of treatments, including scaling and root planing, regenerative procedures, and periodontal surgery to help restore your oral health."
        },
        {
            imgSrc: 'services/pros.jpg',
            alt: 'Prosthodontics',
            icon: 'FaTeeth',
            title: 'Prosthodontics',
            desc: "Prosthodontics is the dental specialty pertaining to the restoration and maintenance of oral function, comfort, appearance, and health by the replacement of missing teeth and contiguous tissues with artificial substitutes. Our prosthodontists at Ivory Medical Center specialize in crafting high-quality dental prosthetics, such as crowns, bridges, dentures, and veneers. Utilizing advanced materials and technology, we provide solutions that not only look natural but also restore functionality and enhance your smile."
        },
        {
            imgSrc: 'services/Dentistry.jpg',
            alt: 'Dentistry',
            icon: 'FaTooth',
            title: 'Dentistry',
            desc: "Ivory Medical Center's general Dentistry services are the backbone of our commitment to holistic oral health. Our experienced dentists provide a full array of dental care, from routine cleanings and fillings to more complex dental work. We place a strong emphasis on dental education and preventive care to help our patients maintain optimal oral health. With a focus on patient comfort and using the latest advancements in dental technology, we ensure that your visit is a pleasant and effective one."
        },

    ];
    return (
        <div className="sub_page">
            <Appointments show={showAppointment} handleClose={handleCloseAppointment} />

            <div className="hero_area">
                {/* Header Section */}
                <header className="header_section">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg custom_nav-container">
                            <a className="navbar-brand" href="#">
                            <img src='logo.png' style={{maxWidth: "100px"}} onClick={() => onComponentChange('home')}/>
                            </a>

                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className=""> </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('home')}>
                                            Home
                                        </a>
                                    </li>
                                    <li className="nav-item active">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('about')}>
                                            About
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('specialities')}>
                                            Specialities
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('doctors')}>
                                            Doctors
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('contact')}>
                                            Contact Us
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => handleShowAppointment()}>
                                            Make an Appointment
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
            <section className="about_section layout_padding" style={{ backgroundColor: "ivory"}}>
                <div className="container">
                    <div className="row">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                            <Col sm={3}>
                                <Nav variant="none" className="flex-column">
                                    {featuresData.map((speciality, index) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={index} style={{ color: "black" }}>{speciality.title}</Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    {featuresData.map((speciality, index) => (
                                        <Tab.Pane key={index} eventKey={index}>
                                            <img src={speciality.imgSrc} alt={speciality.alt} style={{ width: "100%" }} />
                                            <h4>{speciality.title}</h4>
                                            <p>{speciality.desc}</p>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Col>
                        </Tab.Container>
                    </div>
                </div>
            </section>
        </div>

    )

}
export default Specialities
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './Doctors.css';
import Appointments from '../Appointments/Appointments';

function Doctors({ onComponentChange }) {
    const [showAppointment, setShowAppointment] = useState(false);
    const [activeDoctor, setActiveDoctor] = useState(null);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    const openAppointmentModal = (doc) => {
       setActiveDoctor(doc)
       handleShowAppointment()
    };
    const allDoctors = [
        { id: 1, name: 'Dr. Ahmed Adil', image: '/Doctors/adil.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Altayeb Saeed', image: '/Doctors/saeed.jpg', speciality: 'Specialist Oral and Maxillofacial Surgery', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Anas Almidani', image: '/Doctors/almidani.jpg', speciality: 'Specialist Oral and Maxillofacial Surgery', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Khaled Alkateb', image: '/Doctors/khaled.jpg', speciality: 'Specialist Orthodontics', languages: ['English', 'Arabic'] },
        { id: 6, name: 'Dr. Reem Hassan', image: '/Doctors/reem1.jpg', speciality: 'Specialist Endodontics', languages: ['English', 'Arabic'] },
        { id: 7, name: 'Dr. Nada Almaqadm', image: '/Doctors/nada.jpg', speciality: 'General Dentist', languages: ['English', 'Arabic'] },
        { id: 8, name: 'Dr. Essam Abdalla', image: '/Doctors/essam.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 9, name: 'Dr. Rawan Hussein', image: '/Doctors/rawan1.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 10, name: 'Dr. Akram Adil', image: '/Doctors/akram.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 11, name: 'Dr. Esra Mahmud', image: '/Doctors/esra.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 12, name: 'Dr. Mohammed Rehan', image: '/Doctors/rehan.jpg', speciality: 'General Dentist', languages: ['Arabic', 'English'] },
    ];

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));


    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language)));
    });

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };
    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };
    return (
        <div > 
            <Appointments show={showAppointment} handleClose={handleCloseAppointment} doctor={activeDoctor}/>

            <div className="sub_page">
                <div className="hero_area">
                    {/* Header Section */}
                    <header className="header_section">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg custom_nav-container">
                                <a className="navbar-brand" href="#" onClick={() => onComponentChange('home')}>
                                <img src='logo.png' style={{maxWidth: "100px"}}/>
                                </a>

                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className=""> </span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('home')}>
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item active">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('about')}>
                                                About
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('specialities')}>
                                                Specialities
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="doctors.html" onClick={() => onComponentChange('doctors')}>
                                                Doctors
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('contact')}>
                                                Contact Us
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => handleShowAppointment()}>
                                                Make an Appointment
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>
                </div>
            </div>

            <section className="doctor_section layout_padding" style={{ backgroundColor: "ivory" }}>
                
                <div className="container" >
                    <div className="heading_container heading_center">
                        <h2 style={{ color: "black", marginBottom: "70px", fontSize: "25px" }}>
                            Our Doctors
                        </h2>

                    </div>
                    <Row>

                        <Col lg={6}>
                            <Form.Select onChange={handleSpecialityChange} className="" aria-label="Doctor Speciality" style={{ width: "100%" }}>
                                <option value="">All Specialities</option>
                                {specialities.map((speciality, index) => (
                                    <option key={index} value={speciality}>
                                        {speciality}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>




                        <Col lg={6}><Form.Select onChange={handleLanguageChange} aria-label="Doctor Language" style={{ width: "100%" }}>
                            <option value="All">All Language</option>
                            <option value="French">French</option>
                            <option value="English">English</option>
                            <option value="Arabic">Arabic</option>
                            <option value="Turkish">Turkish</option>
                            <option value="Urdu">Urdu</option>
                            <option value="Russian">Russian</option>
                        </Form.Select></Col>



                    </Row>
                    <div className="row">
                        {filteredDoctors.map((doctor, index) => (
                            <div className="col-sm-6 col-lg-4 mx-auto">
                                <div className="box" style={{ backgroundColor: "ivory"}}>
                                    <div className="img-box">
                                        <img src={doctor.image} alt="" style={{ objectFit: "cover", height: "450px" }} />
                                    </div>
                                    <div className="detail-box" >
                                        <div className="social_box" style={{backgroundColor: "ivory"}}>
                                            <a href="#" style={{color: "black", backgroundColor: "ivory"}} onClick={() => openAppointmentModal(doctor)}>
                                                Make an appointment
                                            </a>
                                        </div>
                                        <h5>
                                            {doctor.name}
                                        </h5>
                                        <h6 className="">
                                            {doctor.speciality}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>

                </div>
            </section>
        </div>
    )

}
export default Doctors
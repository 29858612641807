import { FaTooth } from "react-icons/fa6";
import { FaRegSun } from "react-icons/fa6";
import { FaFaceSmileWink } from "react-icons/fa6";
import { FaHandSparkles } from "react-icons/fa6";

function About() {
    return (
        <div>
            <section className="about_section layout_padding" style={{ backgroundColor: "ivory" }}>
                <div className="container">
                    <div className="row" style={{alignItems: "top"}}>
                        <div className="col-md-6">
                            <div className="img-box">
                                <img src="Ivory.jpg" alt="" style={{ borderRadius: '1px' }} />
                            </div>
                            
                        </div>
                        <div className="col-md-6">
                            <div className="detail-box">
                                <div className="heading_container">
                                    <h2 style={{ fontSize: "20px" }}>
                                    Easily Accessible and Welcoming: Your Gateway to Improved Health and Wellness
                                    </h2>
                                </div>
                                <p>
                                    
                                    Nestled in the bustling center of Al Ain, Ivory Medical Center stands as a beacon of health and rejuvenation. Our prime location isn't just a geographical advantage—it's a cornerstone of our commitment to accessible, stress-free healthcare experiences. As you step through our doors, you'll find more than just medical expertise; you'll discover a sanctuary where healing begins the moment you arrive.
                                </p>

                            </div>
                            <div className="detail-box">
                                <div className="heading_container">
                                    <h2 style={{ fontSize: "20px" }}>
                                        Strategically Positioned for Easy Access

                                    </h2>
                                </div>
                                <p >
                                Our facility is strategically placed to ensure that no matter where you're coming from, reaching us is as simple as possible. Whether you're navigating the busy city streets or traveling from a distance, Ivory Medical Center is easily reachable, reducing the hassle in your quest for a healthier, more vibrant lifestyle.
                                </p>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="about_section layout_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="img-box">
                                <img src="ivory.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="img-box">
                                <img src="ivory.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="img-box">
                                <img src="ivory.jpg" alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section> */}

            <section className="department_section layout_padding" style={{ backgroundColor: "ivory", marginTop: "-45px" }}>
                <div className="department_container">
                    <div className="container ">
                        <div className="heading_container heading_center">
                            <h2 style={{ color: "black", fontSize: "25px" }}>Ivory Medical Center</h2>
                            <h2 style={{ color: "black", fontSize: "25px" }}>Your Gateway to Health and Confidence.</h2>

                        </div>
                        <div className="row">
                            <div className="col-md-3" >
                                <div className="box" style={{ backgroundColor: "ivory" }}>
                                    <div className="img-box">
                                        <FaTooth style={{ color: "ivory" }} size={35} />
                                    </div>
                                    <div className="detail-box">
                                        <h5 style={{ marginBottom: '30px', fontSize: "18px" }}>Dental Excellence</h5>
                                        <p>
                                            Your oral health is paramount, and at Ivory Medical Center, we deliver it with precision and care. Our team of dental experts leverages the latest in dental technology to offer a suite of services including routine check-ups, cosmetic dentistry, orthodontics, and more. With a reputation as the preeminent dental clinic in Al Ain, you can trust us to maintain and enhance your smile.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box" style={{ backgroundColor: "ivory" }}>
                                    <div className="img-box" >
                                        <FaRegSun style={{ color: "ivory" }} size={35} />
                                    </div>
                                    <div className="detail-box">
                                        <h5 style={{ fontSize: "18px" }}>Dermatology That Understands Your Skin</h5>
                                        <p>
                                            Skin is your body's largest organ, and it deserves specialized attention. Our board-certified dermatologists are at the forefront of treating skin conditions and improving skin health. We manage everything from acne and eczema to complex skin diseases, offering both medical and aesthetic dermatological care.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box" style={{ backgroundColor: "ivory" }}>
                                    <div className="img-box">
                                        <FaFaceSmileWink style={{ color: "ivory" }} size={35} />
                                    </div>
                                    <div className="detail-box">
                                        <h5 style={{ marginBottom: '10px', fontSize: "18px" }}> Facial Treatments: Your Path to Radiance</h5>
                                        <p>
                                            Revitalize your skin with our bespoke facial treatments designed to cater to your unique skin type and concerns. Our medically-trained aestheticians work magic to rejuvenate and hydrate your skin, ensuring you leave looking as young and invigorated as you feel.                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box" style={{ backgroundColor: "ivory" }}>
                                    <div className="img-box">
                                        <FaHandSparkles style={{ color: "ivory" }} size={35} />

                                    </div>
                                    <div className="detail-box">
                                        <h5 style={{ fontSize: "18px" }}>Laser Hair Removal: Smooth, Lasting Results</h5>
                                        <p>
                                            Say goodbye to unwanted hair with our safe, effective, and virtually pain-free laser hair removal services. At Ivory Medical Center, we utilize the latest laser technology to give you the smooth skin you've always desired, with results that last longer than traditional methods.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="btn-box">
                            <a href="">View All</a>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    )

}
export default About
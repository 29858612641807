import "./FrequentlyAsked.css"

function FrequentlyAsked() {
    return (
        <div className="accordion" style={{ backgroundColor: "ivory" }}>
            <h1 style={{color: "black", fontSize: "30px"}}>Frequently Asked Questions</h1>
            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion1" />
                <label htmlFor="accordion1" className="accordion-item-title">
                    <span className="icon"></span>What services does Ivory Medical Center offer?
                </label>
                <div className="accordion-item-desc">
                    We offer a comprehensive range of services including Dermatology, Oral and Maxillofacial Surgery, Orthodontics, Endodontics, Pediatric Dentistry, Periodontics, and Prosthodontics. Our state-of-the-art facility is equipped to handle your specialized healthcare needs with a team of highly skilled professionals.
                </div>
            </div>

            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion2" />
                <label htmlFor="accordion2" className="accordion-item-title">
                    <span className="icon"></span>How do I book an appointment?
                </label>
                <div className="accordion-item-desc">
                    You can book an appointment by visiting our website and clicking on the 'Book Appointment' feature. Alternatively, you can send us an email at info@ivorymc.ae or call our clinic directly to schedule your visit.
                </div>
            </div>

            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion3" />
                <label htmlFor="accordion3" className="accordion-item-title">
                    <span className="icon"></span>Do you offer personalized care for all ages?
                </label>
                <div className="accordion-item-desc">
                    Yes, our team of compassionate specialists provides personalized care tailored to patients of all ages, ensuring that each individual receives the attention and care suited to their specific needs.
                </div>
            </div>

            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion4" />
                <label htmlFor="accordion4" className="accordion-item-title">
                    <span className="icon"></span>Can I access your services if I have an urgent issue?
                </label>
                <div className="accordion-item-desc">
                    For urgent medical needs, please contact our clinic directly. We understand that timely care is crucial, and we strive to accommodate urgent cases to the best of our ability.
                </div>
            </div>

            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion5" />
                <label htmlFor="accordion5" className="accordion-item-title">
                    <span className="icon"></span>Is there support available for patients after procedures?
                </label>
                <div className="accordion-item-desc">
                    Our commitment to your care continues beyond your visit. We offer comprehensive post-procedure support to ensure that you have the guidance and help you need during your recovery.
                </div>
            </div>
            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion6" />
                <label htmlFor="accordion6" className="accordion-item-title">
                    <span className="icon"></span>How do I know which specialist to see for my condition?
                </label>
                <div className="accordion-item-desc">
                    If you are unsure which specialist to consult, our team can assist you. Based on your symptoms and medical history, we will guide you to the appropriate specialist who can best address your health concerns.                </div>
            </div>
            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion7" />
                <label htmlFor="accordion7" className="accordion-item-title">
                    <span className="icon"></span>What measures are in place to ensure patient safety and privacy?

                </label>
                <div className="accordion-item-desc">
                    Patient safety and privacy are paramount at Ivory Medical Center. We adhere to strict confidentiality protocols and utilize state-of-the-art equipment and procedures to ensure the highest level of safety for our patients.                </div>
            </div>
            <div className="accordion-item" style={{ backgroundColor: "ivory" }}>
                <input type="checkbox" id="accordion8" />
                <label htmlFor="accordion8" className="accordion-item-title">
                    <span className="icon"></span>How can I give feedback about my experience at Ivory Medical Center?
                </label>
                <div className="accordion-item-desc">
                We welcome your feedback as it helps us to improve our services. You can provide feedback through our 'Contact Us' form on the website, via email, or by speaking directly with our clinic staff.                </div>
            </div>

            

        </div>
    )

}
export default FrequentlyAsked
import About from "../About/About"
import FrequentlyAsked from "../FrequetlyAsked/FrequentlyAsked"
import Appointments from '../Appointments/Appointments';
import React, { useState, useEffect } from 'react';

function AboutUs({ onComponentChange }) {

    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    return (
        <div className="sub_page" style={{ backgroundColor: "ivory"}}>
            <Appointments show={showAppointment} handleClose={handleCloseAppointment} />

            <div className="hero_area">
                {/* Header Section */}
                <header className="header_section">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg custom_nav-container">
                            <a className="navbar-brand" href="#">
                            <img src='logo.png' style={{maxWidth: "100px"}} onClick={() => onComponentChange('home')}/>
                            </a>

                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className=""> </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('home')}>
                                            Home
                                        </a>
                                    </li>
                                    <li className="nav-item active">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('about')}>
                                            About
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('specialities')}>
                                            Specialities
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('doctors')}>
                                            Doctors
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => onComponentChange('contact')}>
                                            Contact Us
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{color: "ivory"}} className="nav-link" href="#" onClick={() => handleShowAppointment()}>
                                            Make an Appointment
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
            <About />
            <FrequentlyAsked />

        </div>
    )

}
export default AboutUs